import React from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import _get from 'lodash/get'
import { ArrowRight } from 'react-feather'

import Layout from '../components/Layout'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query NotFoundPageQuery {
        globalSettings: settingsYaml {
          siteTitle
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>404 – Page Not Found</title>
        </Helmet>
        <section className="section thick">
          <div className="container skinny taCenter">
            <h1>404</h1>
            <h2>Sorry! This page isn't available</h2>
            <p>Head back to:{' '}
              <Link to="/" className="circleButton">
                <span className="txt">{_get(data, 'globalSettings.siteTitle')} Home</span>
                <span className="circle"></span>
                <ArrowRight size='15'/>
              </Link>
            </p>
          </div>
        </section>
      </Layout>
    )}
  />
)
